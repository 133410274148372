<template>
    <div class="relative">
        <CookieBanner class="z-50" />

        <Head>
            <title>Mehr Mobilität</title>
            <meta
                head-key="description"
                name="description"
                content="Entdecken Sie mit Leben-ohne-Grenzen die besten Lösungen für Ihre Mobilität im Alter. Von Treppenliften bis zu E-Rollstühlen – wir helfen Ihnen, die passende Option zu finden. Starten Sie jetzt Ihren Check!"
            />
        </Head>

        <HeroSection />
        <!-- <BadgeSection /> -->
        <LogoSection />
        <FeatureSection />
        <StairsAndWheels />
        <TestimonialSection :testimonials="testimonials" />
        <FundingSection />
        <FAQSection :faqs="faqs" />
        <CTASection />
        <TheFooter />
    </div>
</template>

<script setup>
import { Head } from '@inertiajs/vue3'

import HeroSection from '@/Components/Home/HeroSection.vue'
import LogoSection from '@/Components/Home/LogoSection.vue'
import FeatureSection from '@/Components/Home/FeatureSection.vue'
import StairsAndWheels from '@/Components/Home/StairsAndWheels.vue'
import FundingSection from '@/Components/Home/FundingSection.vue'
import TheFooter from '@/Components/PageComponents/TheFooter.vue'
// import BadgeSection from "@/Components/Home/BadgeSection.vue";
import CTASection from '@/Components/Sections/CTASection.vue'
import FAQSection from '@/Components/Sections/FAQSection.vue'
import CookieBanner from '@/Components/CookieBanner.vue'

import TestimonialSection from '@/Components/Sections/TestimonialSection.vue'

const faqs = [
    {
        id: 1,
        question: 'Wie finde ich den passenden Treppenlift?',
        answer: 'Der perfekte Lift ist immer abhängig von Ihren individuellen Bedürfnissen, Vorlieben und baulichen Voraussetzungen. Es gibt unterschiedliche Modelle verschiedener Hersteller, welche alle einen barrierefreien Zugang für Ihr Zuhause gewährleisten, ein selbstständiges Leben ermöglichen und auch eine Alternative zum Pflegeheim darstellen können. Wichtig ist hier, die Gegebenheiten vor Ort zu betrachten und bedarfsorientiert zu entscheiden.',
    },
    {
        id: 2,
        question: 'Wie viel kostet ein Treppenlift?',
        answer: 'Die Kosten für einen Treppenlift sind abhängig von verschiedenen Faktoren. Dazu zählen die Art des Lifts, die Länge der Treppe, die baulichen Gegebenheiten und die individuellen Bedürfnisse. Ein Sitzlift für gerade Treppen ist in der Regel günstiger als ein Plattformlift für kurvige Treppen. Die Preise für einen Treppenlift beginnen bei ca. 2.000 Euro und können bis zu 15.000 Euro betragen.',
    },
    {
        id: 3,
        question: 'Zahlt die Krankenkasse für einen Treppenlift?',
        answer: 'Die Pflegeversicherung der Krankenkasse unterstützt wohnumfeldverbessernde Maßnahmen, wie den Kauf eines Treppenlifts, mit einem finanziellen Zuschuss von bis zu 4.000 Euro pro Person. Für mehrere Personen im gleichen Haushalt gibt es einen möglichen Zuschuss in Höhe von bis zu 4-mal 4.000 Euro bei vorliegendem Pflegegrad.',
    },
    {
        id: 4,
        question: 'Welchen elektrischen Rollstuhl zahlt die Krankenkasse?',
        answer: 'Elektrische Rollstühle gehören zu den medizinischen Hilfsmitteln und werden von den Krankenkassen als Krankenfahrstuhl gelistet. Dementsprechend werden sie bei nachweislicher medizinischer Notwendigkeit auch bezuschusst. Medizinische Voraussetzungen bedingen, ob Sie auf das Hilfsmittel Rollstuhl angewiesen sind und dieser für die individuelle Mobilität unabdingbar ist.',
    },
    {
        id: 5,
        question: 'Kann ich mit einem elektrischen Rollstuhl reisen?',
        answer: 'So einfach können Sie Urlaub mit Rollstuhl machen! Wenn Sie auf einen Rollstuhl angewiesen sind, können Sie Ihre Reise entspannt, auch mit eingeschränkter Mobilität, antreten. Fliegen mit Elektrorollstuhl ist durch unkomplizierten Ablauf am Flughafen ganz einfach. Die Akkus können einfach herausgenommen und im Handgepäck transportiert werden, während ein faltbarer Elektrorollstuhl als normales Gepäck aufgegeben wird.',
    },
    {
        id: 6,
        question: 'Wie kann mir Leben-ohne-Grenzen helfen?',
        answer: 'Unser kostenloser Produkt- und Anbietervergleich unterstützt Sie darin, das Produkt zu finden, welches genau zu Ihren individuellen Ansprüchen und Voraussetzungen passt. Wir übernehmen das Filtern der Angebote und Hersteller, damit Sie mobil und flexibel sein können. Gerne beraten wir Sie auch zu verfügbaren Zuschüssen und Finanzierungsmöglichkeiten. Jetzt den optimalen Treppenlift oder Rollstuhl finden.',
    },
]

const testimonials = [
    {
        id: 1,
        type: 'stairlift',
        title: 'Kann ich nur weiter empfehlen!',
        href: '#',
        body: 'Dank Leben-ohne-Grenzen haben wir den idealen Treppenlift für unsere Bedürfnisse gefunden! Die Beratung war äußerst hilfreich und der Prozess war einfach und unkompliziert. Sehr empfehlenswert!',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Lisa Schulz',
            handle: 'lisaschulz',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 2,
        type: 'stairlift',
        title: 'Sehr zufrieden!',
        href: '#',
        body: 'Meine Eltern und ich bedanken uns herzlich bei Leben-ohne-Grenzen und werden Ihre Plattform gerne weiter empfehlen.',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Helmut Strung',
            handle: 'helmutstrung',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 3,
        type: 'stairlift',
        title: 'Echt super Team :)',
        href: '#',
        body: 'Der Treppenlift-Check hat mir Zeit und Stress erspart. Innerhalb kürzester Zeit hatte ich passende Optionen. Die Expertise des Teams ist wirklich super.',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Maxine Hofmann',
            handle: 'maxinehofmann',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 4,
        type: 'stairlift',
        title: 'Guter Service',
        href: '#',
        body: 'Nachdem ich den Check von Leben-ohne-Grenzen durchgeführt habe, fühle ich mich wie eine Last von meinen Schultern genommen wurde. Danke für einen großartigen Service!',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Carola Fellzer',
            handle: 'carolafellzer',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 5,
        type: 'stairlift',
        title: 'Eltern glücklich und zufrieden',
        href: '#',
        body: 'Als ich mich entschied, einen Treppenlift zu installieren, war ich überwältigt von den vielen Optionen. Dank Leben-ohne-Grenzen wurde die Auswahl viel einfacher und ich bin sehr zufrieden mit meiner Entscheidung.',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Johanna Eckert',
            handle: 'johannaeckert',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 6,
        type: 'wheelchair',
        title: '',
        href: '#',
        body: 'Leben-ohne-Grenzen hat mir geholfen, den perfekten elektrischen Rollstuhl zu finden! Die Beratung war äußerst professionell und das Team war sehr einfühlsam.',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Marlon Beck',
            handle: 'marlonbeck',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 7,
        type: 'wheelchair',
        title: '',
        href: '#',
        body: 'Ich war überwältigt von den vielen Optionen für elektrische Rollstühle, aber dank dem E-Rolli-Check von Leben-ohne-Grenzen wurde die Auswahl viel einfacher.  Ich kann ihren Service nur wärmstens empfehlen!',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Elara Winters',
            handle: 'elarawinters',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 8,
        type: 'wheelchair',
        title: '',
        href: '#',
        body: 'Leben-ohne-Grenzen hat mir den Prozess bis zum Rollstuhl so viel einfacher gemacht. Ich musste nicht mal etwas dazu zahlen, weil alles komplett von der Kasse übernommen wurde.',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Azura Khan',
            handle: 'azurakhan',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 9,
        type: 'wheelchair',
        title: '',
        href: '#',
        body: 'Die Unterstützung, die ich von Leben-ohne-Grenzen erhalten habe, war unglaublich. Sie haben mir geholfen, ganz einfach meinen  E-Rollstuhl zu beantragen, und haben mich durch den gesamten Prozess begleitet.',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Lea Richter',
            handle: 'learichter',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
    {
        id: 10,
        type: 'wheelchair',
        title: '',
        href: '#',
        body: 'Genau das, was ich gebraucht habe, um die richtige Entscheidung zu treffen. Sie haben mir geholfen, einen Rollstuhl zu finden, der nicht nur meinen Bedürfnissen entspricht, sondern auch finanzierbar ist!',
        // imageUrl: "/img/home/lifta-treppenlift.png",
        author: {
            name: 'Sandra Alavi',
            handle: 'sandraalavi',
            // imageUrl: "/img/home/lifta-treppenlift.png",
        },
    },
]
</script>
