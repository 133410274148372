StairAndWheelSection.vue

<template>
    <Section class="overflow-hidden bg-white">
        <Container :threshold="0.1" class="relative bg-slate-50">
            <svg
                class="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                    >
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div class="relative">
                <h2 class="text-center text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    Bahn frei für Mobilität
                </h2>
                <p class="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
                    Wir beraten Sie gerne auf dem Weg zum mobilen Alltag!
                </p>
            </div>

            <div class="relative mt-20 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
                <div class="relative">
                    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">Treppenlifts</h3>
                    <p class="mt-3 text-lg text-gray-500">
                        Wir wissen, dass hinter jeder Entscheidung für einen Treppenlift ein persönlicher und
                        emotionaler Weg steht. Bei Leben-ohne-Grenzen möchten wir diesen Weg für Sie so einfach wie
                        möglich gestalten. Wir finden den perfekten Hersteller für Sie, der einen Treppenlift anbietet,
                        der genau Ihren Bedürfnissen entspricht.
                    </p>

                    <dl class="mt-10 space-y-10">
                        <div v-for="item in stairliftFeatures" :key="item.id" class="relative">
                            <dt>
                                <div
                                    class="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-sky-600 text-white"
                                >
                                    <component :is="item.icon" class="h-8 w-8" aria-hidden="true" />
                                </div>
                                <p class="ml-16 text-lg font-medium leading-6 text-gray-900">
                                    {{ item.name }}
                                </p>
                            </dt>
                            <dd class="ml-16 mt-2 text-base text-gray-500">
                                {{ item.description }}
                            </dd>
                        </div>
                    </dl>
                    <div class="mt-14 flex items-center gap-4 sm:mt-20 sm:gap-6 md:flex-row md:items-center md:gap-x-6">
                        <Link
                            :href="route('stairlift.funnel.mini')"
                            class="rounded-md bg-sky-600 px-3.5 py-2.5 text-center font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
                        >
                            Zum Lift-Check</Link
                        >
                        <Link :href="route('stairlift.index')" class="font-semibold leading-6 text-gray-900"
                            >Mehr erfahren<span aria-hidden="true">→</span></Link
                        >
                    </div>
                </div>

                <div class="relative -mx-4 mt-10 flex h-full flex-col lg:mt-0" aria-hidden="true">
                    <svg
                        class="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                        width="784"
                        height="404"
                        fill="none"
                        viewBox="0 0 784 404"
                    >
                        <defs>
                            <pattern
                                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                    </svg>
                    <div
                        class="relative mx-auto flex-grow overflow-hidden rounded-xl bg-slate-200 shadow-md ring-1 ring-slate-300"
                    >
                        <img src="/img/home/seniorin-treppe.jpeg" alt="" class="h-full w-full object-cover" />
                    </div>
                    <!-- <img class="relative mx-auto" width="490"
                        src="https://tailwindui.com/img/features/feature-example-1.png" alt="" /> -->
                </div>
            </div>

            <svg
                class="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                    >
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>

            <div class="relative mt-12 sm:mt-16 lg:mt-32">
                <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
                    <div class="lg:col-start-2">
                        <h3 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">E-Rollstühle</h3>
                        <p class="mt-3 text-lg text-gray-500">
                            In vielen Lebenslagen ist der Rollstuhl als ständiger Begleiter unabdingbar. Wir helfen
                            Ihnen schnell und einfach zu Ihrem E-Rollstuhl, denn Ihre Bewegungsfreiheit sollte keine
                            Frage sein. Wir machen Sie nicht trotz, sondern gerade wegen Ihres Rollstuhls mobil!
                        </p>

                        <dl class="mt-10 space-y-10">
                            <div v-for="item in wheelchairFeatures" :key="item.id" class="relative">
                                <dt>
                                    <div
                                        class="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-lime-600 text-white"
                                    >
                                        <component :is="item.icon" class="h-8 w-8" aria-hidden="true" />
                                    </div>
                                    <p class="ml-16 text-lg font-medium leading-6 text-gray-900">
                                        {{ item.name }}
                                    </p>
                                </dt>
                                <dd class="ml-16 mt-2 text-base text-gray-500">
                                    {{ item.description }}
                                </dd>
                            </div>
                        </dl>
                        <div
                            class="mt-14 flex items-center gap-4 sm:mt-20 sm:gap-6 md:flex-row md:items-center md:gap-x-6"
                        >
                            <Link
                                :href="route('wheelchair.funnel')"
                                class="rounded-md bg-lime-600 px-3.5 py-2.5 text-center font-semibold text-white shadow-sm hover:bg-lime-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-700"
                            >
                                Zum E-Rolli-Check</Link
                            >
                            <Link :href="route('wheelchair.index')" class="font-semibold leading-6 text-gray-900">
                                Mehr erfahren<span aria-hidden="true">→</span></Link
                            >
                        </div>
                    </div>

                    <div class="relative -mx-4 mt-10 flex h-full flex-col lg:col-start-1 lg:mt-0">
                        <svg
                            class="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                            width="784"
                            height="404"
                            fill="none"
                            viewBox="0 0 784 404"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="20"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                        </svg>
                        <div
                            class="relative mx-auto flex-grow overflow-hidden rounded-xl bg-slate-200 shadow-md ring-1 ring-slate-300 lg:mr-10"
                        >
                            <img
                                src="/img/home/rollstuhl-see.jpeg"
                                alt=""
                                class="h-full w-full object-cover object-left"
                            />
                        </div>
                        <!-- <img class="relative mx-auto" width="490"
                            src="https://tailwindui.com/img/features/feature-example-2.png" alt="" /> -->
                    </div>
                </div>
            </div>
        </Container>
    </Section>
</template>

<script setup>
import Section from '@/Components/Fragments/BaseSection.vue'
import Container from '@/Components/Fragments/BaseContainer.vue'
import { Link } from '@inertiajs/vue3'

import { BanknotesIcon, BoltIcon, SquaresPlusIcon, TrophyIcon } from '@heroicons/vue/24/outline'

const stairliftFeatures = [
    {
        id: 1,
        name: 'Individuelle Lifttypen',
        description:
            'Finden Sie mit unserem Check die perfekte Lösung für Ihre spezifischen Anforderungen und Gegebenheiten. Ob Treppen-, Plattform-, oder Hublift, wir beraten Sie zu Ihrem Weg zwischen den Etagen.',
        icon: SquaresPlusIcon,
    },
    {
        id: 2,
        name: 'Finanzierung',
        description:
            'Wir unterstützen Sie nicht nur bei der Suche nach dem passenden Lift, sondern auch dabei, Ihre möglichen Finanzierungswege zu finden. Bei uns finden Sie ganz einfach und unverbindlich die richtigen überregionalen und regionalen Zuschüsse.',
        icon: BanknotesIcon,
    },
    {
        id: 3,
        name: 'Expertenwissen',
        description:
            'Ein Treppenlift soll das Leben erleichtern und Sorgen nehmen. Um das zu ermöglichen, begleiten wir Sie durch den Prozess und arbeiten mit Fachkräften daran, Ihre Wünsche persönlich zu erfüllen. Unsere Mobilitätsexperten stehen Ihnen auch bei schwierigen Fragen zur Seite.',
        icon: TrophyIcon,
    },
]
const wheelchairFeatures = [
    {
        id: 1,
        name: 'Finanzierung',
        description:
            'Durch Zuschüsse der Krankenkasse ist die Anschaffung ganz einfach. Wenn ein nachgewiesener Bedarf für einen elektrischen Rollstuhl vorliegt, haben sie keinen Eigenanteil und der E-Rolli wird Ihnen ohne Kosten überlassen. Ein solcher Bedarf wird durch ein Rezept vom behandelnden Arzt bestätigt.',
        icon: BanknotesIcon,
    },
    {
        id: 2,
        name: 'Mobilität',
        description:
            'Mit einem E-Rollstuhl können sich vollkommen neue Freiheiten eröffnen und viele Wege werden leichter. Damit Sie sich im Alltag, sowie auf Reisen flexibel bewegen können, ist der E-Rolli vollkommen faltbar und mitnahmefähig.   ',
        icon: BoltIcon,
    },
]
</script>
