<template>
    <div class="bg-white">
        <header class="absolute inset-x-0 top-0 z-50">
            <div class="mx-auto max-w-7xl">
                <div class="px-6 pt-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
                    <nav class="flex items-center justify-between lg:justify-start" aria-label="Global">
                        <Link :href="route('home')" class="-m-1.5 p-1.5">
                            <span class="sr-only">Leben-ohne-Grenzen</span>
                            <!-- <img
                                alt="Your Company"
                                class="h-8 w-auto"
                                src="https://tailwindui.com/img/logos/mark.svg?color=sky&shade=600"
                            /> -->
                            <ApplicationLogo class="block h-12 w-auto fill-current text-gray-800" />
                        </Link>
                        <button
                            type="button"
                            class="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden"
                            @click="setOpen(true)"
                        >
                            <span class="sr-only">Open main menu</span>
                            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                        </button>
                        <div class="hidden lg:ml-12 lg:flex lg:gap-x-14">
                            <Link
                                v-for="item in navigation"
                                :key="item.name"
                                :href="item.href"
                                class="text-sm font-semibold leading-6 text-gray-900"
                                >{{ item.name }}</Link
                            >
                        </div>
                    </nav>
                </div>
            </div>
            <MobileDialog :navItems="mobileNav" :show="isOpen" @setOpen="setOpen" />
        </header>

        <div class="relative">
            <div class="mx-auto max-w-7xl">
                <div class="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
                    <svg
                        class="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        aria-hidden="true"
                    >
                        <polygon points="0,0 90,0 50,100 0,100" />
                    </svg>

                    <div class="relative px-6 py-20 sm:py-32 lg:px-8 lg:pr-0 xl:py-56">
                        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                            <div class="hidden sm:mb-10 sm:flex">
                                <div
                                    class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                                >
                                    Sprechen Sie mit einem unserer Mobilitätsexperten.

                                    <a href="tel:+4922182829650" class="whitespace-nowrap font-semibold text-sky-600"
                                        ><span class="absolute inset-0" aria-hidden="true" />Anrufen
                                        <span aria-hidden="true">&rarr;</span></a
                                    >
                                </div>
                            </div>
                            <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Weg frei für mehr Mobilität!
                            </h1>
                            <p class="mt-6 text-lg leading-8 text-gray-600">
                                Leben-ohne-Grenzen hilft Ihnen dabei, mehr zu erreichen. Ob Treppenlifts für Zuhause
                                oder E-Rollstühle für unterwegs, wir helfen Ihnen auf dem ganzen Weg. Von der Beratung,
                                über die Förderung bis hin zur Beauftragung, sind wir für Sie da!
                            </p>
                            <div class="mt-10 flex items-start space-x-1">
                                <Link
                                    :href="route('stairlift.index')"
                                    class="rounded-l-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                >
                                    Lift-Check
                                    <span aria-hidden="true">→</span></Link
                                >
                                <Link
                                    :href="route('wheelchair.index')"
                                    class="rounded-r-md bg-lime-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                                >
                                    E-Rolli-Check
                                    <span aria-hidden="true">→</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    class="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
                    src="img/home/home-hero.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import ApplicationLogo from '../ApplicationLogo.vue'
import { Link } from '@inertiajs/vue3'
import MobileDialog from '../AppLayout/MobileDialog.vue'

const navigation = [
    { name: 'Treppenlifts', href: route('stairlift.index') },
    { name: 'E-Rollstühle', href: route('wheelchair.index') },
    { name: 'Ratgeber', href: route('blog.index') },
]

const isOpen = ref(false)
function setOpen(value) {
    isOpen.value = value
}

const mobileNav = [
    { name: 'Treppenlifts', href: route('stairlift.index') },
    { name: 'E-Rollstühle', href: route('wheelchair.index') },
    { name: 'Ratgeber', href: route('blog.index') },
    { name: 'Log in', href: route('dashboard') },
]
</script>
